import { useToast } from 'vue-toastification';
import { CSRF_COOKIE_KEY, CSRF_HEADER_KEY } from '~/constants/csrfKeys';
import ToastComponent from '~/components/ui/ToastComponent.vue';
import type { UseFetchOptions } from 'nuxt/app';

export async function useCustomFetch<T>(url: string, options: UseFetchOptions<T> = {}, version: string = '1') {
  const { apiUrl } = useRuntimeConfig().public;
  const requestHeaders: any = useRequestHeaders(['cookie']);

  const csrfToken = useCookie(CSRF_COOKIE_KEY).value;

  if (csrfToken) {
    requestHeaders[CSRF_HEADER_KEY] = csrfToken;
  }

  requestHeaders.accept = 'application/json';

  const { data, error, status, execute } = await useFetch(url, {
    $fetch: $fetch,
    baseURL: apiUrl + version,
    credentials: 'include',
    mode: 'cors',
    headers: requestHeaders,
    ...options,
  });

  if (error.value && error.value.statusCode) {
    if (import.meta.client && [401, 403, 500, 502].includes(error.value.statusCode) && error.value.data?.message) {
      const toast = useToast();
      toast.error({ component: ToastComponent, props: { text: error.value.data.message } });
    }

    if (String(error.value.statusCode) === '404') {
      navigateTo({ path: '/page-not-found', replace: true });
    }
  }

  return { data, error, status, execute };
}
